<template>
  <section id="bottom-call" class="relative overflow-hidden 2xl:px-0">
    <div class="bg-white py-12 lg:pt-28 lg:pb-24 2xl:max-w-7xl xl:max-w-6xl lg:max-w-5xl w-11/12 mx-auto rounded-md">
      <div class="max-w-2xl mx-auto text-center">
        <div class="w-full flex justify-center mb-4">
          <div
              v-if="props.category"
              class=" w-fit relative text-center bg-secondary uppercase font-bold brother rounded"
            >
              <p class="px-4 py-1">{{ props.category }}</p>
            </div>
        </div>

        <h6
          class="text-black lg:text-5xl text-4xl mx-auto max-w-xl lg:px-0 mb-8 gradient-clip" style="letter-spacing: -2px;">{{ props.title ? props.title : $t('footer.ready_to_start') }} <span>{{ props.span ? props.span : $t('footer.your_project') }}</span><br></h6>
        <p class="text-black text-base max-w-lg mx-auto mb-8 px-4"
        :class="props.text ? 'font-normal' : ''">{{ props.text ? props.text : $t('footer.our_team_of_developers') }}</p>
        <nuxt-link 
          :aria-label="$t('footer.contact_us')" 
          :to="localePath('/contact')"
          class="button button-outline"
          :class="props.btnBlack ? 'btn-black' : ''"
          ><span></span>{{ props.btnText ?  props.btnText : $t('footer.contact_us') }}</nuxt-link>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
 const props = defineProps({
   category: String,
   btnBlack: Boolean,
   title: String,
   span: String,
   text: String,
    btnText: String
 })
</script>
