<template>
    <header class="w-full block absolute">
        <div
                v-bind:class="{'hide-menu':hideMenu}"
                class="pl-4 lg:pl-0 lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl mx-auto top-0 flex items-center lg:justify-end text-right pt-4">
            <nuxt-link :aria-label="$t('menu.home')" :to="localePath('/')">
                <img width="144" height="48" v-if="dark && !mobileMenuOpen" src="~/assets/images/Rollin_black.svg"
                     class="w-36 relative z-30" alt="Rollin Logo"/>
                <img width="144" height="48" v-else src="~/assets/images/Rollin_white.svg" class="w-36 relative z-30"
                     alt="Rollin Logo"/>
            </nuxt-link>

            <nav class="hidden grow lg:flex items-center justify-end">
                <nav>
                    <ul class="flex justify-end pr-4">
                        <li v-if="hideServices" class="pl-12 mx-3 py-8" v-bind:class="{'text-black':dark}">
                            <nuxt-link :aria-label="$t('menu.services')" v-bind:class="{'dark':dark}" class="has-children" :to="localePath('/services/')">
                                <span @click="trackEvent('/services')">{{ $t('menu.services') }}</span>
                            </nuxt-link>
                        </li>
                        <li v-else class="xl:pl-12 mx-3 py-8" v-bind:class="{'text-black':dark}"
                            v-on:mouseover="reveal('services',true)" v-on:mouseout="reveal('services',false)">
                            <nuxt-link :aria-label="$t('menu.services')" v-bind:class="{'dark':dark}" id="services" class="has-children" :to="localePath('/services/')">
                                <span @click="trackEvent('/services')">{{ $t('menu.services') }}</span>
                            </nuxt-link>
                            <div
                                    v-bind:class="{ reveal:services }"
                                    class="text-black sub-menu drop-shadow-2xl shadow-black p-3 rounded-md absolute">
                                <div class="w-full flex">
                                    <div class="w-1/3 dark-section p-3 text-white text-left">
                                        <div>
                                            <p class="text-lg mb-1 font-bold font-brother header text-secondary">{{ $t('pages.home.development') }}</p>
                                            <p class="text-xs">{{ $t('menutexts.development') }}</p>
                                            <div class="border-b border-slate-800 mt-4 mb-2"></div>
                                        </div>
                                        <ul class="flex flex-col justify-between h57">
                                            <li>
                                                <nuxt-link :aria-label="$t('menutexts.customweb')" class="sublink" :to="localePath('/services/custom-web-development/')">
                                                    <span class="icon development"></span>
                                                    <span class="link-content" @click="trackEvent('custom_web_development')">{{
                                                            $t('menu.customweb')
                                                        }}
                        <small>{{ $t('menutexts.customweb') }}</small></span>
                                                </nuxt-link>
                                            </li>
                                            <li>
                                                <nuxt-link :aria-label="$t('menutexts.drupalweb')" class="sublink" :to="localePath('/services/drupal-development/')"><span
                                                        class="icon drupal"></span>
                                                    <span class="link-content" @click="trackEvent('drupal_web_development')">{{
                                                            $t('menu.drupalweb')
                                                        }}
                        <small>{{ $t('menutexts.drupalweb') }}</small></span>
                                                </nuxt-link>
                                            </li>
                                            <li>
                                                <nuxt-link :aria-label="$t('menutexts.craftweb')" class="sublink" :to="localePath('/services/craft-cms-development/')"><span
                                                        class="icon craft"></span>
                                                    <span class="link-content" @click="trackEvent('craft_web_development')">
                              {{ $t('menu.craftweb') }}
                        <small>{{ $t('menutexts.craftweb') }}</small></span>
                                                </nuxt-link>
                                            </li>
                                            <li>
                                                <nuxt-link :aria-label="$t('menutexts.optimizely')" :to="localePath('/services/optimizely-cms-development/')"><span
                                                        class="icon optimizely"></span>
                                                    <span class="link-content" @click="trackEvent('optimizely')">{{ $t('menu.optimizely') }}
                        <small>{{ $t('menutexts.optimizely') }}</small></span>
                                                </nuxt-link>
                                            </li>
                                            <li>
                                                <nuxt-link :aria-label="$t('menutexts.interfaces')" class="sublink" :to="localePath('/services/custom-interfaces/')"><span
                                                        class="icon interface"></span>
                                                    <span class="link-content" @click="trackEvent('custom_interfaces')">{{ $t('menu.interfaces') }}
                        <small>{{ $t('menutexts.interfaces') }}</small></span>
                                                </nuxt-link>
                                            </li>
                                            <li>
                                                <nuxt-link :aria-label="$t('menutexts.mobile')" class="sublink" :to="localePath('/services/mobile-apps/')"><span
                                                        class="icon mobile"></span>
                                                    <span class="link-content" @click="trackEvent('mobile_apps')">{{ $t('menu.mobile') }}
                        <small>{{ $t('menutexts.mobile') }}</small></span>
                                                </nuxt-link>
                                            </li>
                                            <li>
                                                <nuxt-link :aria-label="$t('menutexts.ecommerce')" class="sublink" :to="localePath('/services/e-commerce/')"><span
                                                        class="icon ecommerce"></span>
                                                    <span class="link-content" @click="trackEvent('ecommerce')">{{
                                                            $t('menu.ecommerce')
                                                        }}
                        <small>{{ $t('menutexts.ecommerce') }}</small></span>
                                                </nuxt-link>
                                            </li>
                                        </ul>

                                    </div>
                                    <div class="w-2/3 pl-3">
                                        <div class="flex flex-col">
                                            <div class="w-full bg-slate-100 rounded-md p-4 mb-3 flex gap-3 items-end">
                                                <div class="w-1/2">
                                                    <div>
                                                        <p class="text-lg mb-1 font-bold font-brother header text-secondary">{{ $t('pages.home.design') }}</p>
                                                        <p class="text-xs">{{ $t('menutexts.designstrategy') }}</p>
                                                        <div class="border-b border-slate-200 my-4"></div>
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            <nuxt-link :aria-label="$t('menutexts.design')" class="sublink" :to="localePath('/services/design-and-creative/')">
                                                                <span class="icon design"></span>
                                                                <span class="link-content" @click="trackEvent('design')">{{ $t('menu.design') }}<small>
                            {{ $t('menutexts.design') }}</small></span>
                                                            </nuxt-link>
                                                        </li>
                                                        <li>
                                                            <nuxt-link :aria-label="$t('menutexts.vrar')" class="sublink" :to="localePath('/services/vr-experiences/')"><span
                                                                    class="icon vrar"></span>
                                                                <span class="link-content" @click="trackEvent('vrar')">{{ $t('menu.vrar') }}
                        <small>{{ $t('menutexts.vrar') }}</small></span>
                                                            </nuxt-link>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="w-1/2">
                                                    <ul>
                                                        <li>
                                                            <nuxt-link :aria-label="$t('menutexts.contentstrategy')" class="sublink" :to="localePath('/services/content-strategy/')"><span
                                                                    class="icon contentstrategy"></span>
                                                                <span class="link-content" @click="trackEvent('contentstrategy')">{{ $t('menu.contentstrategy') }}
                        <small>{{ $t('menutexts.contentstrategy') }}</small></span>
                                                            </nuxt-link>
                                                        </li>
                                                        <li>
                                                            <nuxt-link :aria-label="$t('menutexts.research')" class="sublink" :to="localePath('/services/research-consultation/')"><span
                                                                    class="icon research"></span>
                                                                <span class="link-content" @click="trackEvent('research')">{{ $t('menu.research') }}
                        <small>{{ $t('menutexts.research') }}</small></span>
                                                            </nuxt-link>
                                                        </li>
                                                        <li>
                                                            <nuxt-link :aria-label="$t('menutexts.audits')" class="sublink" :to="localePath('/services/technical-audits/')"><span
                                                                    class="icon audits"></span>
                                                                <span class="link-content" @click="trackEvent('audits')">{{ $t('menu.audits') }}
                        <small>{{ $t('menutexts.audits') }}</small></span>
                                                            </nuxt-link>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                            <div class="w-full flex gap-3">
                                                <div class="w-1/2 bg-slate-100 rounded-md p-3">
                                                    <div>
                                                        <p class="text-lg mb-1 font-bold font-brother header leading-6 text-secondary">{{ $t('pages.home.automation') }}</p>
                                                        <p class="text-xs">{{ $t('menutexts.automationai') }}</p>
                                                        <div class="border-b border-slate-200 my-4"></div>
                                                    </div>
                                                    <ul class="flex justify-between flex-col h57">
                                                        <li>
                                                            <nuxt-link :aria-label="$t('menutexts.automation')" class="sublink" :to="localePath('/services/automation/')"><span
                                                                    class="icon automation"></span>
                                                                <span class="link-content" @click="trackEvent('automation')">{{ $t('menu.automation') }}
                        <small>{{ $t('menutexts.automation') }}</small></span>
                                                            </nuxt-link>
                                                        </li>
                                                        <li>
                                                            <nuxt-link :aria-label="$t('menutexts.emails')" class="sublink" :to="localePath('/services/email-journeys/')">
                                                                <span class="icon emails"></span>
                                                                <span class="link-content" @click="trackEvent('emails')">{{ $t('menu.emails') }}
                        <small>{{ $t('menutexts.emails') }}</small></span>
                                                            </nuxt-link>
                                                        </li>
                                                        <li>
                                                            <nuxt-link :aria-label="$t('menutexts.ai')" class="sublink" :to="localePath('/services/artificial-intelligence/')"><span
                                                                    class="icon ai"></span>
                                                                <span class="link-content" @click="trackEvent('ai')">{{ $t('menu.ai') }}
                          <small>{{ $t('menutexts.ai') }}</small>
                        </span>
                                                            </nuxt-link>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="w-1/2 flex flex-col gap-3">
                                                    <div class="bg-slate-100 rounded-md p-3 flex">
                                                        <div class="w-2/5 mr-3">
                                                            <p class="text-lg font-bold font-brother header leading-6 mb-4 text-secondary">{{ $t('pages.home.security') }}</p>
                                                            <p class="text-xs">{{ $t('menutexts.securitycomp') }}</p>
                                                            <div class="border-b border-slate-200 my-2"></div>
                                                        </div>
                                                        <ul>
                                                            <li>
                                                                <nuxt-link :aria-label="$t('menutexts.security')" class="sublink" :to="localePath('/services/security/')"><span
                                                                        class="icon security"></span>
                                                                    <span class="link-content" @click="trackEvent('security')">{{ $t('menu.security') }}
                                    <small>{{ $t('menutexts.security') }}</small>
                              </span>
                                                                </nuxt-link>
                                                            </li>
                                                            <li>
                                                                <nuxt-link :aria-label="$t('menutexts.accessibility')" class="sublink" :to="localePath('/services/accessibility/')"><span
                                                                        class="icon accessibility"></span>
                                                                    <span class="link-content" @click="trackEvent('accessibility')">{{ $t('menu.accessibility') }}
                                    <small>{{ $t('menutexts.accessibility') }}</small>
                        </span>
                                                                </nuxt-link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="bg-slate-100 rounded-md p-3 flex">
                                                        <div class="w-2/5 mr-3">
                                                            <p class="text-lg font-bold font-brother header leading-6 mb-4 text-secondary">{{ $t('pages.home.maintenance') }}</p>
                                                            <p class="text-xs">{{ $t('menutexts.maintenanceevol') }}</p>
                                                            <div class="border-b border-slate-200 my-2"></div>
                                                        </div>
                                                        <ul>
                                                            <li>
                                                                <nuxt-link :aria-label="$t('menutexts.maintenance')" class="sublink" :to="localePath('/services/maintenance/')"><span
                                                                        class="icon maintenance"></span>
                                                                    <span class="link-content" @click="trackEvent('maintenance')">{{ $t('menu.maintenance') }}
                                    <small>{{ $t('menutexts.maintenance') }}</small>
                      </span>
                                                                </nuxt-link>
                                                            </li>
                                                            <li>
                                                                <nuxt-link :aria-label="$t('menutexts.cloudhosting')" class="sublink" :to="localePath('/services/cloud-infrastructure/')"><span
                                                                        class="icon cloud"></span>
                                                                    <span class="link-content" @click="trackEvent('cloudhosting')">{{ $t('menu.cloudhosting') }}
                                    <small>{{ $t('menutexts.cloudhosting') }}</small>
                        </span>
                                                                </nuxt-link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="mx-3 py-8" v-bind:class="{'text-black':dark}">
                            <nuxt-link :aria-label="$t('menu.casestudies')" :to="localePath('/case-studies/')"><span
                                    @click="trackEvent('case_studies')">{{ $t('menu.casestudies') }}</span></nuxt-link>
                        </li>
                        <li v-if="hideAbout" class="mx-3 py-8 relative" v-bind:class="{'text-black':dark}">
                            <nuxt-link :aria-label="$t('menu.about')" v-bind:class="{'dark':dark}" class="has-children" to="/about-us/">
                                <span @click="trackEvent('about_us')">{{ $t('menu.about') }}</span>
                            </nuxt-link>
                        </li>
                        <li v-else class="mx-3 py-8 relative" v-bind:class="{'text-black':dark}"
                            v-on:mouseover="reveal('about',true)" v-on:mouseout="reveal('about',false)">
                            <nuxt-link :aria-label="$t('menu.about')" v-bind:class="{'dark':dark}" class="has-children" id="about" :to="localePath('/about-us/')">
                                <span @click="trackEvent('about_us')">{{ $t('menu.about') }}</span>
                            </nuxt-link>
                            <div
                                    v-bind:class="{ reveal:about }"
                                    class="text-black sub-menu2 drop-shadow-2xl shadow-black p-4 rounded-md absolute">
                                <div class="max-w-7xl mx-auto flex">
                                    <ul class="w-full">
                                        <li>
                                            <!--                      <nuxt-link :aria-label="$t('menu.industries')" class="sublink" :to="localePath('/industries/')"><span class="icon industries"></span>-->
                                            <!--                        <span class="link-content" @click="trackEvent('industries')">{{ $t('menu.industries') }}-->
                                            <!--                        <small>{{ $t('about-texts.industries') }}</small></span>-->
                                            <!--                      </nuxt-link>-->
                                            <a :href="localePath('/industries/')" :aria-label="$t('menu.industries')" class="sublink"><span class="icon industries"></span>
                                                <span class="link-content" @click="trackEvent('industries')">{{ $t('menu.industries') }}
                        <small>{{ $t('about-texts.industries') }}</small></span>
                                            </a>
                                        </li>
                                        <li>
                                            <nuxt-link :aria-label="$t('about-texts.why-rollin')" class="sublink" :to="localePath('/about-us/why-rollin/')"><span
                                                    class="icon award"></span>
                                                <span class="link-content" @click="trackEvent('why_rollin')">{{ $t('menu.whyrollin') }}<small>{{
                                                        $t('about-texts.why-rollin')
                                                    }}</small></span>
                                            </nuxt-link>
                                        </li>
                                        <li>
                                            <nuxt-link :aria-label="$t('menu.careers')" class="sublink" :to="localePath('/about-us/careers/')">
                                                <span class="icon career"></span>
                                                <span class="link-content" @click="trackEvent('careers')">{{ $t('menu.careers') }}
                        <small>{{ $t('about-texts.careers') }}</small></span>
                                            </nuxt-link>
                                        </li>
                                        <li>
                                            <nuxt-link :aria-label="$t('menu.resources')" class="sublink" :to="localePath('/resources/')">
                                                <span class="icon resource"></span>
                                                <span class="link-content" @click="trackEvent('resources')">{{ $t('menu.resources') }}
                        <small>{{ $t('about-texts.resources') }}</small></span>
                                            </nuxt-link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li class="mx-3 py-8" v-bind:class="{'text-black':dark}">
                            <nuxt-link :aria-label="$t('menu.access')" class="lock" v-bind:class="{'dark':dark}" :to="localePath('/login/')">
                                <span @click="trackEvent('case_studies')">{{ $t('menu.access') }}</span>
                            </nuxt-link>
                        </li>
                    </ul>
                </nav>
                <ul class="languages border-l-indigo-50 border-l-2 pl-6">
                    <li v-bind:class="{'text-black':dark}">
                        <nuxt-link aria-label="Français" v-if="locale === 'en'" :to="switchLocalePath('fr')">Français</nuxt-link>
                        <nuxt-link aria-label="English" v-else :to="switchLocalePath('en')">English</nuxt-link>
                    </li>
                </ul>
                <ul v-if="!hideContact" class="flex justify-end">
                    <li class="ml-8">
                        <glow-button :to="localePath('/contact/')">
                            {{ $t('menu.contact') }}
                        </glow-button>
                    </li>
                </ul>
            </nav>

            <div class="lg:hidden ml-auto">
                <button aria-label="Menu" @click="toggleMenu" v-bind:class="{'lightMode':!dark, 'is-active':mobileMenuOpen}"
                        class="hamburger hamburger--slider relative z-30" type="button">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
                </button>
            </div>


            <nav v-bind:class="{'menu-active':mobileMenuOpen}" class="fixed inset-0 z-20 mobile-menu flex flex-col justify-center bg-white">

                <div class="overflow-hidden absolute w-full h-full pointer-events-none top-0 z-[-1]">
                    <div class="hide-slow pink-radial top-right-radial absolute"></div>
                    <div class="hide-slow white-radial bottom-left-radial absolute"></div>
                </div>

                <ul class="flex flex-col justify-center items-start h-screen pl-4 pt-8">
                    <li class="py-3">
                        <nuxt-link :aria-label="$t('menu.home')" :to="localePath('/')">
                            <span class="icon home"></span>
                            <span @click="trackEvent('mobile_home')">{{ $t('menu.home') }}</span>
                        </nuxt-link>
                    </li>
                    <li class="py-3">
                        <nuxt-link :aria-label="$t('menu.services')" :to="localePath('/services/')">
                            <span class="icon services"></span>
                            <span @click="trackEvent('mobile_services')">{{ $t('menu.services') }}</span>
                        </nuxt-link>
                    </li>
                    <li class="py-3">
                        <nuxt-link :aria-label="$t('menu.casestudies')" :to="localePath('/case-studies/')">
                            <span class="icon work"></span>
                            <span @click="trackEvent('mobile_case_studies')">{{ $t('menu.casestudies') }}</span>
                        </nuxt-link>
                    </li>
                    <li class="py-3">
                        <nuxt-link :aria-label="$t('menu.about')" :to="localePath('/about-us/')">
                            <span class="icon about"></span>
                            <span @click="trackEvent('mobile_about_us')">{{ $t('menu.about') }}</span>
                        </nuxt-link>
                    </li>
                    <li class="py-3">
                        <nuxt-link :aria-label="$t('menu.resources')" :to="localePath('/resources/')">
                            <span class="icon resources"></span>
                            <span @click="trackEvent('mobile_resources')">{{ $t('menu.resources') }}</span>
                        </nuxt-link>
                    </li>
                    <li class="py-3">
                        <nuxt-link :aria-label="$t('menu.industries')" :to="localePath('/industries/')">
                            <span class="icon industries"></span>
                            <span @click="trackEvent('industries')">{{ $t('menu.industries') }}</span>
                        </nuxt-link>
                    </li>
                    <li class="py-3">
                        <nuxt-link :aria-label="$t('menu.contact')" :to="localePath('/contact/')">
                            <span class="icon contact"></span>
                            <span @click="trackEvent('mobile_contact')">{{ $t('menu.contact') }}</span>
                        </nuxt-link>
                    </li>
                </ul>
                <ul class="bottom-menu">
                    <li class="py-3">
                        <nuxt-link :aria-label="$t('menu.access')" :to="localePath('/login/')">
                            <span class="icon clientaccess"></span>
                            <span @click="trackEvent('client_access')">{{ $t('menu.access') }}</span>
                        </nuxt-link>
                    </li>
                    <li class="py-3 language">
                        <nuxt-link aria-label="Français" v-if="locale === 'en'" :to="switchLocalePath('fr')">Français</nuxt-link>
                        <nuxt-link aria-label="English" v-else :to="switchLocalePath('en')">English</nuxt-link>
                    </li>
                    <li class="py-3">
                        <a aria-label="Follow Rollin on LinkedIn" @click="trackEvent('social_linkedIn')" href="https://linkedin.com/company/rollin-studios/" target="_blank" rel="nofollow noopener noreferrer">
                            <img src="~/assets/images/linkedin.svg" width="96" height="24" class="w-24 ml-2" alt="LinkedIn" loading="lazy"/>
                        </a>
                    </li>

                </ul>

            </nav>

        </div>
    </header>
</template>
<script>
import glowButton from "../../blocks/glow-button/glow-button";

export default {
    data() {
        return {
            services: false,
            about: false,
            hideServices: false,
            hideAbout: false,
            locale: this.$i18n.locale,
            mobileMenuOpen: false,
        }
    },
    setup() {
        const {$gsap} = useNuxtApp()
        return {
            gsap: $gsap
        }
    },
    components: {'glow-button': glowButton},
    props: ['dark', 'hideContact', 'hideMenu'],
    watch: {
        mobileMenuOpen: function (val) {
            const body = document.querySelector('body')
            const html = document.querySelector('html')
            if (val) {
                body.style.overflow = 'hidden'
                html.style.overflow = 'hidden'
            } else {
                body.style.overflow = 'auto'
                html.style.overflow = 'auto'
            }
        }
    },
    methods: {
        toggleMenu() {
            this.mobileMenuOpen = !this.mobileMenuOpen
        },
        reveal(module, state) {
            if (module === 'services') {
                this.services = state
            } else {
                this.about = state
            }
        },
        trackEvent(link) {
            const body = document.querySelector('body')
            const html = document.querySelector('html')
            body.style.overflow = 'auto'
            html.style.overflow = 'auto'
            const {gtag} = useGtag()
            gtag('event', 'click_menu', {
                'event_category': 'menu',
                'event_label': 'click_' + link
            })
        }
    },
    mounted() {
        let body = document.querySelector('body')
        let html = document.querySelector('html')
        body.style.overflow = 'auto'
        html.style.overflow = 'auto'
        const menuChildrens = document.querySelectorAll('.has-children')
        menuChildrens.forEach((menu) => {
            menu.addEventListener('keydown', (e) => {
                if (e.key === 'Enter') {
                    e.preventDefault()
                    if (e.target.id === 'services') {
                        this.services = true
                        this.about = false
                    }
                    if (e.target.id === 'about') {
                        this.about = true
                        this.services = false
                    }
                }
                if (e.key === 'Escape') {
                    e.preventDefault()
                    this.services = false
                    this.about = false
                }
            })
        })

        const subLinks = document.querySelectorAll('.sublink')
        subLinks.forEach((link) => {
            link.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    e.preventDefault()
                    this.services = false
                    this.about = false
                }
            })
        })

        if (this.$route.path === '/services/' || this.$route.path === '/fr/services/') {
            this.hideServices = true
        }
        if (this.$route.path === '/about-us/' || this.$route.path === '/fr/a-propos/') {
            this.hideAbout = true
        }
        setTimeout(() => {
            this.hideServices = false
            this.hideAbout = false
        }, 1000)
    }
}
</script>
