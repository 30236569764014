import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

export default defineNuxtPlugin((nuxtApp) => {
    if (process.client) {
        gsap.registerPlugin(ScrollTrigger)
        gsap.registerPlugin(SplitText)

        nuxtApp.vueApp.use(gsap)
        nuxtApp.provide('gsap', gsap)
        nuxtApp.provide('ScrollTrigger', ScrollTrigger)
        nuxtApp.provide('SplitText', SplitText)
    }
})